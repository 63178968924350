import { apiRequest } from "utils/async/apiUtils";
import { message } from "antd";

export const loadSettingDetails = async (
  setResData = () => {},
  setLoaded = () => {}
) => {
  try {
    const res = await apiRequest("get", "site-setting");

    const data = res.data;
    if (data.status === 200) setResData(data.data);
    setLoaded(true);
    return data;
  } catch (err) {
    setLoaded(true);
    return err;
  }
};

export const loadSocialMediaList = async (
  setResData = () => {},
  setLoaded = () => {}
) => {
  try {
    const res = await apiRequest("get", "social-media");

    const data = res.data;
    if (data.status === 200) setResData(data.data);
    setLoaded(true);
    return data;
  } catch (err) {
    setLoaded(true);
    return err;
  }
};

export const editSettings = async (data) => {
  try {
    const res = await apiRequest(
      "put",
      "site-setting/update",
      {
        body: {
          id: data?.id,
          title: data?.title,
          description: data?.description,
          email: data?.email,
          phone: data?.phone,
          appStore: data?.appStore,
          playStore: data?.playStore,
          copyright: data?.copyright,
          "address[address1]": data?.address,
          logo: data?.fileList,
          favicon: data?.favIcon,
          ...data?.socialMedia,
        },
      },
      "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};
